import React from 'react';
import { graphql } from 'gatsby';

import ContentPage from '~/components/Content/Page';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';

const IndexPage = React.forwardRef(({ data }, ref) => {
  return (
    <Layout transparentNav={data?.home.transparentNav} navColor={data?.home.navColor}>
      <Metadata />
      <ContentPage page={data.home} ref={ref} />
    </Layout>
  );
});

export default IndexPage;

export const query = graphql`
  query IndexPage($locale: String) {
    home: contentfulPage(slug: { eq: "home" }, node_locale: { eq: $locale }) {
      ...ContentPageFragment
    }
  }
`;
